// Copyright (C) 2022 by Posit Software, PBC.

import { deleteAppImage, getApp, getAppBy, getAppImage, updateApp, updateAppImage } from '@/api/app';
import { drainPaging, getContentVisits, getShinyUsage } from '@/api/instrumentation';
import { getUser } from '@/api/users';
// eslint-disable-next-line import/named
import AppModes from '@/api/dto/appMode';
import AppRoles from '@/api/dto/appRole';
import { updateGitRepository } from '@/api/git';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';
import { UPDATE_APP_METADATA } from './contentView';

export const INFO_SETTINGS_INIT = 'INFO_SETTINGS_INIT';
export const INFO_SETTINGS_RECEIVED = 'INFO_SETTINGS_RECEIVED';
export const INFO_SETTINGS_ERROR = 'INFO_SETTINGS_ERROR';
export const FETCH_USAGE = 'FETCH_USAGE';
export const USAGE_DATA_RECEIVED = 'USAGE_DATA_RECEIVED';
export const SESSION_TIME_RECEIVED = 'SESSION_TIME_RECEIVED';
export const USAGE_DATA_ERROR = 'USAGE_DATA_ERROR';
export const RESOLVE_CHART_DATA = 'RESOLVE_CHART_DATA';
export const CHART_DATA_RECEIVED = 'CHART_DATA_RECEIVED';
export const RESET_CHART_DATA = 'RESET_CHART_DATA';
export const THUMBNAIL_URL_RECEIVED = 'THUMBNAIL_URL_RECEIVED';
export const IMAGE_RECEIVED = 'IMAGE_RECEIVED';
export const FETCH_IMAGE = 'FETCH_IMAGE';
export const INFO_SETTINGS_DISCARD = 'INFO_SETTINGS_DISCARD';
export const SAVE_INFO_SETTINGS = 'SAVE_INFO_SETTINGS';

// Constants from go code.
// https://github.com/rstudio/connect/blob/e16f085ca8ca0e658feadd24cc0b054d34adf3e2/src/connect/api/instrumentation/types.go#L18
const MAX_CONTENT_USAGE_API_LIMIT = 500;
const CONTENT_USAGE_API_VERSION = '1';
const CONTENT_USAGE_API_TOTAL_LIMIT = 10000;

// We use this format to represent day labels.
const DAY_FORMAT = 'll';

dayjs.extend(duration);
dayjs.extend(minMax);

/**
 * Enum covering the different API Types for the Content Usage APIs
 *
 * @typedef {number} ContentUsageAPIType
 * @readonly
 * @enum {ContentUsageAPIType}
 */
export const ContentUsageAPIType = {
  General: 'general',
  Shiny: 'shiny',
};

/**
 * Utility method to determine what API should be called to retrieve
 * usage data for a specific content type.
 *
 * Note: Not all content types are supported by the APIs.
 *
 * @param {AppMode} appMode - Application Type
 *
 * @returns {ContentUsageAPIType} - The Corresponding API Type which
 *          indicates the API which should be used to retrieve usage data
 *          from the Connect server.
 */
export function getContentUsageAPIType(appMode) {
  switch (appMode) {
    case AppModes.Shiny:
    case AppModes.ShinyRmd:
    case AppModes.ShinyQuarto:
    case AppModes.PythonShiny:
      return ContentUsageAPIType.Shiny;
    default:
      return ContentUsageAPIType.General;
  }
}

function userObjectToOwnerProperties(user) {
  return {
    ownerEmail: user.email,
    ownerFirstName: user.firstName,
    ownerLastName: user.lastName,
    ownerUsername: user.username,
    ownerLocked: user.locked,
  };
}

const staticRmdAppDescriptor = (isSite, hasParameters) => {
  if (isSite) {
    return 'R Markdown site';
  } else if (hasParameters) {
    return 'Parameterized R Markdown document';
  }
  return 'R Markdown document';
};

const staticQuartoAppDescriptor = isSite => {
  if (isSite) {
    return 'Quarto project';
  }
  return 'Quarto document';
};

const staticAppDescriptor = (isSite, isPlot, isPin) => {
  if (isSite) {
    return 'Static site';
  } else if (isPlot) {
    return 'Static plot';
  } else if (isPin) {
    return 'Pin';
  }
  return 'Static document';
};

const isSite = app => app.contentCategory === 'site' || app.contentCategory === 'book';

const isPlot = app => app.contentCategory === 'plot';

const isPin = app => app.contentCategory === 'pin';

function getAppTypeDescription(app) {
  switch (app.appMode) {
    case AppModes.Unknown:
      return 'Incomplete content';

    case AppModes.Shiny:
      return 'Shiny application';

    case AppModes.ShinyRmd:
      return 'Shiny R Markdown document';

    case AppModes.ShinyQuarto:
      return 'Shiny Quarto document';

    case AppModes.StaticRmd:
      return staticRmdAppDescriptor(isSite(app), app.hasParameters);

    case AppModes.StaticQuarto:
      return staticQuartoAppDescriptor(isSite(app));

    case AppModes.Static:
      return staticAppDescriptor(isSite(app), isPlot(app), isPin(app));

    case AppModes.PlumberApi:
    case AppModes.PythonApi:
    case AppModes.PythonFastAPI:
      return 'API';

    case AppModes.PythonDash:
      return 'Dash application';

    case AppModes.PythonStreamlit:
      return 'Streamlit application';

    case AppModes.PythonBokeh:
      return 'Bokeh application';

    case AppModes.PythonShiny:
      return 'Python Shiny application';

    case AppModes.TensorFlowApi:
      return 'TensorFlow Model API';

    case AppModes.StaticJupyter:
      return 'Jupyter notebook';

    case AppModes.JupyterVoila:
      return 'Voila interactive notebook';

    default:
      return 'Incomplete content';
  }
}

export function initialState() {
  return {
    gitDeployment: null,
    app: {
      name: null,
      title: null,
      description: '',
      git: {
        enabled: null
      },
    },
    /**
     * Contains the working copy of changes that can be committed.
     * Dirty should be computed based on the state of these.
     */
    working: {
      title: null,
      description: '',
      image: null,
      checkForUpdates: null,
    },
    appImage: null,
    thumbnailURL: '',
    error: null,
    canEdit: false,
    canEditImage: false,
    usageData: [],
    usageError: null,
    canViewUsage: false,
    imageError: null,
    gitError: null,
    apiType: null,
    accessCount: null,
    maxAccessCount: null,
    accessByDay: null,
    sessionTime: null,
    partial: null,
    chartData: {
      datasets: [],
      labels: [],
    },
    contentCreatedDateLabel: null,
  };
}

export default {
  state: initialState(),
  mutations: {
    [INFO_SETTINGS_RECEIVED](state, {
      app,
      canEdit,
      canEditImage,
      contentCreatedDateLabel,
      owner,
      gitDeployment,
      canViewUsage,
    }) {
      state.app = {
        ...app,
        ...userObjectToOwnerProperties(owner),
        // Extremely narrow edge case. Only possible in bundle deploy.
        title: app.title || app.name,
        typeDescription: getAppTypeDescription(app),
      };
      state.canEdit = canEdit;
      state.canEditImage = canEditImage;
      state.contentCreatedDateLabel = contentCreatedDateLabel;
      state.gitDeployment = gitDeployment;
      state.canViewUsage = canViewUsage;
    },
    [INFO_SETTINGS_ERROR](state, error) {
      state.error = error;
    },
    [USAGE_DATA_RECEIVED](
      state,
      {
        usageData,
        accessCount,
        maxAccessCount,
        accessByDay,
        apiType,
        partial,
        sessionTime = null
      }
    ) {
      state.apiType = apiType;
      state.accessCount = accessCount;
      state.maxAccessCount = maxAccessCount;
      state.accessByDay = accessByDay;
      state.sessionTime = sessionTime;
      state.partial = partial;
      state.usageData = usageData;
    },
    [USAGE_DATA_ERROR](state, error) {
      state.usageError = error;
    },
    [CHART_DATA_RECEIVED](state, chartData) {
      state.chartData = chartData;
    },
    [RESET_CHART_DATA](state) {
      state.chartData = {
        datasets: [],
        labels: [],
      };
    },
    [THUMBNAIL_URL_RECEIVED](state, thumbnailURL) {
      state.thumbnailURL = thumbnailURL;
    },
    [IMAGE_RECEIVED](state, appImage) {
      state.appImage = appImage;
    },
    [INFO_SETTINGS_DISCARD](state) {
      Object.assign(state, initialState());
    }
  },
  actions: {
    async [INFO_SETTINGS_INIT]({ commit, dispatch, rootState: { currentUser: { user } } }, guid) {
      // clear error, if any
      commit(INFO_SETTINGS_ERROR, null);
      // clear working copy
      commit(INFO_SETTINGS_DISCARD);
      try {
        const app = await getAppBy(guid);
        // TODO: Implement a v1 git api
        const app2 = await getApp(app.id);
        // When no git, we return null instead of the git object.
        // This is not great as it means we have to fall back on the frontend! FIXME in go code
        app.git = app2.git || {};
        const gitDeployment = !!app2.git;
        const owner = await getUser(app.ownerGuid);
        const canEdit = user.canEditAppSettings(app);
        const canEditImage = canEdit && AppRoles.isCollaborator(app.appRole);
        const canViewUsage = user.isPublisher() || user.isAdmin();
        const contentCreatedDateLabel = dayjs(app.createdTime).subtract(1)
          .format(DAY_FORMAT);
        commit(INFO_SETTINGS_RECEIVED, { app,
          canEdit,
          canEditImage,
          owner,
          contentCreatedDateLabel,
          gitDeployment,
          canViewUsage });
        if (canViewUsage) {
          dispatch(FETCH_USAGE);
        }
        dispatch(FETCH_IMAGE);
      } catch (err) {
        commit(INFO_SETTINGS_ERROR, err);
      }
    },
    async [FETCH_USAGE]({ state, commit, dispatch }) {
      try {
        const apiType = getContentUsageAPIType(state.app.appMode);
        /* eslint-disable camelcase */
        const params = {
          content_guid: state.app.guid,
          from: dayjs()
            .subtract(30, 'days')
            .format('YYYY-MM-DDTHH:mm:ss[Z]'),
          min_data_version: CONTENT_USAGE_API_VERSION,
          limit: MAX_CONTENT_USAGE_API_LIMIT,
          asc_order: false,
        };
        /* eslint-enable camelcase */
        let usageData = [], sessionTime = 0;
        switch (apiType) {
          case ContentUsageAPIType.Shiny:
            usageData = await drainPaging(getShinyUsage, params, CONTENT_USAGE_API_TOTAL_LIMIT);
            sessionTime = usageData.reduce((time, data) => {
              return time.add(
                dayjs(data.ended).diff(dayjs(data.started))
              );
            }, dayjs.duration(0));
            break;
          case ContentUsageAPIType.General:
            usageData = await drainPaging(getContentVisits, params, CONTENT_USAGE_API_TOTAL_LIMIT);
            break;
        }
        let accessCount = usageData.length;
        const accessByDay = usageData.reduce((map, data) => {
          const day = dayjs(data.time || data.started).format(DAY_FORMAT);
          map[day] = map[day] ? map[day] + 1 : 1;
          return map;
        }, {});
        const maxAccessCount =
          Object.values(accessByDay).reduce((max, next) => Math.max(max, next), 0);
        const partial = (accessCount >= CONTENT_USAGE_API_TOTAL_LIMIT);
        if (partial) {
          accessCount = `${CONTENT_USAGE_API_TOTAL_LIMIT / 1000 }K+`;
          sessionTime = 0;
        }
        commit(
          USAGE_DATA_RECEIVED,
          { usageData, apiType, accessByDay, accessCount, maxAccessCount, sessionTime, partial }
        );
        dispatch(RESOLVE_CHART_DATA);
      } catch (err) {
        commit(USAGE_DATA_ERROR, err);
      }
    },
    [RESOLVE_CHART_DATA]({ state, commit }) {
      const { accessByDay } = state;
      const accessDays = Object.keys(accessByDay).map(day => dayjs(day, DAY_FORMAT, true));
      const cutoff = dayjs.min(accessDays);
      const days = Array.from({ length: 30 }, (e, i) => dayjs().subtract(29 - i, 'days'));
      // We have to do this kinda strangely. ChartJS ignores NaN, so NaN represents everything outside the current line.
      const beforeCutoff = days
        .map(day => (day.isBefore(cutoff) ? 0 : NaN));
      const afterCutoff = days
        .map(day => (day.isSameOrAfter(cutoff) ? accessByDay[day.format(DAY_FORMAT)] || 0 : NaN));
      commit(CHART_DATA_RECEIVED, {
        datasets: [
          {
            // Actual content usage line
            data: afterCutoff,
            backgroundColor: '#c7ddef',
            borderColor: '#337ab7',
            borderWidth: 1,
            fill: true,
            tension: 0, // - Disable Bezier Curves for harsher graph...
          },
          {
            // lead line before deployment
            data: beforeCutoff,
            backgroundColor: '#999999',
            borderColor: '#999999',
            borderWidth: 1,
            fill: false,
            tension: 0, // - Disable Bezier Curves for harsher graph...
            borderDash: [3, 3],
          },
        ],
        labels: days.map(day => day.format(DAY_FORMAT))
      });
    },
    async [FETCH_IMAGE]({ state, commit }) {
      const { guid } = state.app;
      try {
        const appImageBlob = await getAppImage(guid);
        if (appImageBlob.size === 0) {
          commit(IMAGE_RECEIVED, null);
        } else {
          commit(IMAGE_RECEIVED, appImageBlob);
          // MUST revoke the image URL!!!
          const appImageURL = window.URL.createObjectURL(appImageBlob);
          commit(THUMBNAIL_URL_RECEIVED, appImageURL);
        }
      } catch (e) {
        commit(INFO_SETTINGS_ERROR, e);
      }
    },
    async [SAVE_INFO_SETTINGS]({
      state,
      dispatch,
      commit
    }, {
      title,
      description,
      image,
      repositoryEnabled: checkForUpdates,
      imageCleared
    }) {
      try {
        await updateApp(state.app.id, {
          title,
          description,
        });
        commit(UPDATE_APP_METADATA, { title, description });
        if (image) {
          await updateAppImage(state.app.guid, image);
        } else if (imageCleared) {
          await deleteAppImage(state.app.guid);
        }
        if (state.gitDeployment) {
          await updateGitRepository(state.app.id, { enabled: checkForUpdates });
        }
        dispatch(INFO_SETTINGS_INIT, state.app.guid);
      } catch (e) {
        commit(INFO_SETTINGS_ERROR, e);
      }
    }
  }
};
